<template>
  <div>
    <Popup
      v-if="store.state.isShoppingPanelVisible"
      :disable-page-scroll="false"
      @onClose="onClose"
    >
      <ShoppingCart
        v-if="store.state.isShoppingPanelVisible"
        :model="model"
        @show-guide="onGuideShow"
      />
    </Popup>
  </div>
</template>

<script>
import store from '@/CVI/WebCore/store'
import Popup from '@/CVI/WebCore/components/Navigation/ServiceMenu/ServiceMenuPanel.vue'
import tracker from '@/CVI/WebCore/core-tracker'

export default {
  components: {
    Popup,
    ShoppingCart: () => import('@/CVI/Purchase/components/ShoppingCart/ShoppingCartNavigationPanel.vue')

  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      store,
      isGuideVisible: false
    }
  },
  methods: {
    onClose() {
      this.store.state.isShoppingPanelVisible = false
      tracker.trackSimpleEvent({
        event: 'menuNavigationShoppingCartClose'
      })
    },
    onGuideShow() {
      this.isGuideVisible = true
    }
  }
}
</script>
